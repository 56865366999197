<template>
  <v-app>
      <v-main class="bg">
          <v-container fluid>
              <router-view></router-view>
          </v-container>
      </v-main>
  </v-app>
  </template>
  
  <script>
  export default {
      name: "page-layout",
      components: {},
      data() {
          return {
          };
      },
      methods: {},
  };
  </script>
  
  <style scoped>
  .bg {
      background: linear-gradient(359.61deg, #fdebfa 10.1%, #F3EFF7 54.91%, #F3EFF7 98.41%);
  }
  </style>
  