<template>
  <v-app>
    <v-main class="auth-pages" style="background-color: white;">

      <div class="">
        <!-- <span class="mask bg-gradient-light opacity-0"></span> -->
        <v-container style="padding-top: 0px;">
          <v-row class="">
              <v-container class="">
                <router-view></router-view>
              </v-container>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>


export default {
  name: "auth-layout",
  components: {
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
  },
};
</script>
