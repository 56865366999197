import Vue from "vue";
import VueRouter from "vue-router";
import LandingPageLayout from "../views/Layout/LandingPageLayout";
import AuthLayout from "../views/Layout/AuthLayout";
import CheckmongLayout from "../views/Layout/CheckmongLayout";
import AzqLayout from "../views/Layout/AzqLayout";
import TokenMenagementLayout from "../views/Layout/TokenManagementLayout";
import DmkLayout from "../views/Layout/DementiaKnowledgeLayout";
import EbookForPublic from "../views/Layout/EbookForPublicLayout";
import SimpleAzqLayout from "../views/Layout/SimpleAzqLayout";



Vue.use(VueRouter);

const beforeEnter = (to, from, next) => {
  //console.log('from:',from)
  //console.log('from:',from.path )
  const store = {};
  Object.assign(store, JSON.parse(localStorage.getItem('store')))
  if (!store.login_detail.user_token) {
    return next({ path: '/home' });
  }
  else{
    return next();
  }
 
}

const isLogedInLine = (to, from, next) => {
  const store = {};
  Object.assign(store, JSON.parse(localStorage.getItem('store')))
  if (!store.register_form.lineid) {
    return next({ path: '/login' });
   }
   next();
}




// const beforeEnter = (to, from, next) => {
//   const store = {};
//   // const hasToken = Boolean(localStorage.getItem('store'));
//   Object.assign(store, JSON.parse(localStorage.getItem('store')))
//   //console.log(//console.log(store))
//   if (store.browser.user == '') {
//     return next({ path: '/home' });
//   }
//   next();
// }

///////////////////////////////////////////////////////////////////////////////////


let azq = {
  path: "/",
  component: AzqLayout,
  name: "Azq Layout",
  children: [
    {
      path: "/azq_public",
      name: "ประเมินแบบทดสอบสมองให้ผู้ป่วย",
      component: () => import("@/views/public_pages/AzqPublic.vue"),
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/azq",
      name: "azq",
      component: () => import("@/views/private_pages/Azq.vue"),
    },
    {
      path: "/azq_provider",
      name: "azq provider",
      component: () => import("@/views/private_pages/healthcare_provider/AzqProvider.vue"),
      beforeEnter,
    },
    {
      path: "/azq_result",
      name: "Azq Result",
      component: () => import("@/views/private_pages/patient/AzqResult.vue"),
      
      meta: {
        groupName: "Patient",
      },
    },

    
  ]
};



let checkmongDashboard = {
  path: "/",
  component: CheckmongLayout,
  name: "Checkmong Layout",
  children: [

    {
      path: "/user_info",
      name: "ข้อมูลบัญชีผู้ใช้",
      component: () => import("@/views/private_pages/UserInfo.vue"),
      beforeEnter,
      meta: {
        groupName: "Both",
      },
    },
    
    {
      path: "/video_training",
      name: "VideoTraning",
      component: () => import("@/views/private_pages/VideoTraning.vue"),
      beforeEnter,
      meta: {
        groupName: "Both",
      },
    },
    {
      path: "/book_docter_add_reg_only",
      name: "Book Docter Add reg only",
      component: () => import("@/views/private_pages/patient/BookDocterAddRegOnly.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/book_docter_add",
      name: "Book Docter Add",
      component: () => import("@/views/private_pages/patient/BookDocterAdd.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/book_doctor_list",
      name: "นัดพบแพทย์",
      component: () => import("@/views/private_pages/patient/BookDoctorList.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/calendar_patient",
      name: "ปฏิทินนัดพบแพทย์",
      component: () => import("@/views/private_pages/patient/CalendarPatient.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/medicine_alert",
      name: "ตารางกินยา",
      component: () => import("@/views/private_pages/patient/MedicineAlert.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/medicine_alert_add",
      name: "MedicineAleartAdd",
      component: () => import("@/views/private_pages/patient/MedicineAlertAdd.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/patient_health_record",
      name: "ข้อมูลสุขภาพ",
      component: () => import("@/views/private_pages/patient/PatientHealthRecord.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/patient_health_record_add",
      name: "PatientHealthRecordAdd",
      component: () => import("@/views/private_pages/patient/PatientHealthRecordAdd.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/patient_health_record_noline",
      name: "PatientHealthRecordNoLine",
      component: () => import("@/views/private_pages/patient/PatientHealthRecordNoLine.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },

    {
      path: "/qr_gen",
      name: "สร้างคิวอาร์โค้ดของฉัน",
      component: () => import("@/views/private_pages/patient/QrGen.vue"),
      beforeEnter,
      meta: {
        groupName: "Patient",
      },
    },

    {
      path: "/calendar_provider",
      name: "ปฏิทินรายการนัดหมาย",

      component: () => import("@/views/private_pages/healthcare_provider/CalendarProvider.vue"),
      beforeEnter,
      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/dashboard_summary",
      name: "ภาพรวมข้อมูลผู้ป่วย",
      component: () => import("@/views/private_pages/healthcare_provider/DashboardSummary.vue"),
      beforeEnter,
      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/member_azq_list",
      name: "รายชื่อผู้ป่วยในการดูแล",
      component: () => import("@/views/private_pages/healthcare_provider/MemberAZQList.vue"),
      beforeEnter,
      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/member_patient_data_list",
      name: "รายชื่อผู้ป่วยในการดูแล",
      component: () => import("@/views/private_pages/healthcare_provider/MemberPatientDataList.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/member_patient_health_add_list",
      name: "รายชื่อผู้ป่วยในการดูแล",
      component: () => import("@/views/private_pages/healthcare_provider/MemberPatientHealthAddList.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/provider_info",
      name: "ข้อมูลบัญชีผู้ใช้งาน",
      component: () => import("@/views/private_pages/healthcare_provider/ProviderInfo.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/scan_accessdata",
      name: "สแกนคิวอาร์โค้ด",
      component: () => import("@/views/private_pages/healthcare_provider/ScanAccessdata.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/scan_add_health_record",
      name: "สแกนคิวอาร์โค้ด",
      component: () => import("@/views/private_pages/healthcare_provider/ScanAddHealthRecord.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/scan_add_patient",
      name: "สแกนคิวอาร์โค้ด",
      component: () => import("@/views/private_pages/healthcare_provider/ScanAddPatient.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/scan_azq",
      name: "สแกนคิวอาร์โค้ด",
      component: () => import("@/views/private_pages/healthcare_provider/ScanAzq.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/view_add_patient",
      name: " ข้อมูลผู้ป่วย",
      component: () => import("@/views/private_pages/healthcare_provider/ViewAddPatient.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/add_patient_manual",
      name: "เพิ่มผู้ป่วยด้วยตัวเอง",
      component: () => import("@/views/private_pages/healthcare_provider/AddPatientManual.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/view_patient_health_record_no_line",
      name: "ViewPatientHealthRecordNoLine",
      component: () => import("@/views/private_pages/healthcare_provider/ViewPatientHealthRecordNoLine.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/view_patient_health_record",
      name: "ข้อมูลสุขภาพผู้ป่วย",
      props: true,
      component: () => import("@/views/private_pages/healthcare_provider/ViewPatientHealthRecord.vue"),
      beforeEnter,

      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/view_patient_health_record_add",
      name: "เพิ่มข้อมูลสุขภาพผู้ป่วย",
      props: true,
      component: () => import("@/views/private_pages/healthcare_provider/ViewPatientHealthRecordAdd.vue"),
      beforeEnter,
      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/home_checkmong",
      name: "เมนูหลัก",
      component: () => import("@/views/private_pages/HomeCheckmong.vue"),
      beforeEnter,

    },
    
  ],
};

let authPage = {
  path: "/",
  component: AuthLayout,
  name: "authPage Layout",
  children: [
    {
      path: "/export_azq",
      name: "Exprt azq",
      component: () => import("@/views/public_pages/azq_component_scene/export_azq.vue"),
      meta: {
        groupName: "Patient",
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/authen/Login.vue"),
    },
    {
      path: "/token_management_login",
      name: "token management login",
      component: () => import("@/views/authen/TokenManagementLogin.vue"),
    },
    {
      path: "/register_select_type",
      name: "Register select type",
      component: () => import("@/views/authen/RegisterSelectType.vue"),
      isLogedInLine
    },
    {
      path: "/register_form",
      name: "Register form",
      component: () => import("@/views/authen/RegisterForm.vue"),
      isLogedInLine
    },
    {
      path: "/register_passcode",
      name: "Register passcode",
      component: () => import("@/views/authen/RegisterPasscode.vue"),
      isLogedInLine
    },
    {
      path: "/forget_passcode",
      name: "Forget passcode",
      component: () => import("@/views/authen/ForgetPasscode.vue"),
      isLogedInLine
    },
    {
      path: "/login_passcode",
      name: "login passcode",
      component: () => import("@/views/authen/LoginPasscode.vue"),
      isLogedInLine
    },
    {
      path: "/pdpa",
      name: "PDPA",
      component: () => import("@/views/authen/Pdpa.vue"),
      isLogedInLine
    },
    {
      path: "/pdpa_simple_azq",
      name: "pdpa_simple_azq",
      component: () => import("@/views/public_pages/pdpa_simple_azq.vue"),
      isLogedInLine
    },

    {
      path: "/passcode_check",
      name: "pass code",
      component: () => import("@/views/private_pages/healthcare_provider/PasscodeCheck.vue"),
      isLogedInLine,
      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/del_user",
      name: "del_user",
      component: () => import("@/views/authen/DelUser.vue"),
      beforeEnter,
      meta: {
        groupName: "healthcare_provider",
      },
    },
    {
      path: "/gateway",
      name: "gateway",
      component: () => import("@/views/authen/Gateway.vue"),
      meta: {
        groupName: "healthcare_provider",
      },
    },
  ],
};

let EbookForPublicPage = {
  path: "/",
  component: EbookForPublic,
  name: "EbookForPublicPage",
  children: [
    {
      path: "/ebook_for_public",
      name: "EbookForPublic",
      component: () => import("@/views/public_pages/EbookForPublic.vue"),
    },
    
  ]
};

let SimpleAzqLayoutPage = {
  path: "/",
  component: SimpleAzqLayout,
  name: "SimpleAzqLayoutPage",
  children: [
    
    {
      path: "/select_azq",
      name: "select_azq",
      component: () => import("@/views/public_pages/select_azq.vue"),
    },
    {
      path: "/select_brain_library",
      name: "select_brain_library",
      component: () => import("@/views/public_pages/select_brain_library.vue"),
    },
    {
      path: "/simple_azq_start",
      name: "simple_azq_start",
      component: () => import("@/views/public_pages/simple_azq_start.vue"),
    },
    {
      path: "/simple_azq_reg",
      name: "simple_azq_reg",
      component: () => import("@/views/public_pages/simple_azq_reg.vue"),
    },
    {
      path: "/simple_azq_1",
      name: "simple_azq_1",
      component: () => import("@/views/public_pages/simple_azq_1.vue"),
    },
    {
      path: "/simple_azq_2",
      name: "simple_azq_2",
      component: () => import("@/views/public_pages/simple_azq_2.vue"),
    },
    {
      path: "/simple_azq_3",
      name: "simple_azq_3",
      component: () => import("@/views/public_pages/simple_azq_3.vue"),
    },
    {
      path: "/simple_azq_4",
      name: "simple_azq_4",
      component: () => import("@/views/public_pages/simple_azq_4.vue"),
    },
    {
      path: "/simple_azq_5",
      name: "simple_azq_5",
      component: () => import("@/views/public_pages/simple_azq_5.vue"),
    },
    {
      path: "/simple_azq_6",
      name: "simple_azq_6",
      component: () => import("@/views/public_pages/simple_azq_6.vue"),
    },
    {
      path: "/simple_azq_7",
      name: "simple_azq_7",
      component: () => import("@/views/public_pages/simple_azq_7.vue"),
    },
    {
      path: "/simple_azq_8",
      name: "simple_azq_8",
      component: () => import("@/views/public_pages/simple_azq_8.vue"),
    },
    {
      path: "/simple_azq_9",
      name: "simple_azq_9",
      component: () => import("@/views/public_pages/simple_azq_9.vue"),
    },
    {
      path: "/simple_azq_10",
      name: "simple_azq_10",
      component: () => import("@/views/public_pages/simple_azq_10.vue"),
    },
    {
      path: "/simple_azq_11",
      name: "simple_azq_11",
      component: () => import("@/views/public_pages/simple_azq_11.vue"),
    },
    {
      path: "/simple_azq_12",
      name: "simple_azq_12",
      component: () => import("@/views/public_pages/simple_azq_12.vue"),
    },
    {
      path: "/simple_azq_13",
      name: "simple_azq_13",
      component: () => import("@/views/public_pages/simple_azq_13.vue"),
    },
    {
      path: "/simple_azq_14",
      name: "simple_azq_14",
      component: () => import("@/views/public_pages/simple_azq_14.vue"),
    },
    {
      path: "/simple_azq_result",
      name: "simple_azq_result",
      component: () => import("@/views/public_pages/simple_azq_result.vue"),
    }
    
  ]
};




let dmk = {
  path: "/",
  component: DmkLayout,
  name: "dmk Layout",
  children: [
    {
      path: "/dementia_knowledge",
      name: "แหล่งความรู้",
      component: () => import("@/views/public_pages/DementiaKnowledge.vue"),
    },
    {
      path: "/dementia_knowledge_general",
      name: "เรื่องทั่วไป",
      component: () => import("@/views/public_pages/DementiaKnowledgeGeneral.vue"),
    },
    {
      path: "/dementia_knowledge_activity",
      name: "กิจกรรมพัฒนาสมอง",
      component: () => import("@/views/public_pages/DementiaKnowledgeActivity.vue"),
    },
    
  ]
};


let landingPage = {
  path: "/",
  redirect: "/home",
  component: LandingPageLayout,
  name: "LandingPage Layout",
  children: [
    {
      path: "/home",
      name: "Home",
      component: () => import("@/views/public_pages/LandingPage.vue"),

    },
    {
      path: "/find_provider",
      name: "Find Provider",
      component: () => import("@/views/public_pages/FindProvider.vue"),

    },
    // {
    //   path: "/dementia_knowledge",
    //   name: "Dementia Knowledge",
    //   component: () => import("@/views/public_pages/DementiaKnowledge.vue"),
    // },
    {
      path: "/update_dementia_knowledge",
      name: "Update Dementia Knowledge",
      component: () => import("@/views/public_pages/UpdateDementiaKnowledge.vue"),
    },
  ],
};

let TokenManagementPage = {
  path: "/",
  redirect: "/token_management",
  component: TokenMenagementLayout,
  name: "TokenMenagementLayout",
  children: [
    {
      path: "/admin_dashboard",
      name: "dashboard",
      component: () => import("@/views/private_pages/admin/Dashboard.vue"),
   

    },
    {
      path: "/token_management",
      name: "token_management",
      component: () => import("@/views/private_pages/admin/TokenManagement.vue"),


    },
  ],
};

let notExit = {
  path: "/:catchAll(.*)",
  redirect: "/home_checkmong",
};


const routes = [
  landingPage,
  EbookForPublicPage,
  SimpleAzqLayoutPage,
  dmk,
  authPage,
  checkmongDashboard,
  azq,
  TokenManagementPage,
  notExit,
  
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
