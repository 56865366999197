<template>
  <v-app >
    <drawer v-show="!$vuetify.breakpoint.mobile" :sidebarColor="sidebarColor" :sidebarTheme="sidebarTheme"> </drawer>
    <v-main :class="{ not_mobile: !$vuetify.breakpoint.mobile }" >
      <app-bar
        v-show="!$vuetify.breakpoint.mobile"
        background=""
        linkColor="rgba(0,0,0,.6)"
      >
      </app-bar>
      <v-container class="my-auto" >
        <v-row class="min-vh-100" >

            <v-container class="pb-0 position-relative" >
              <router-view></router-view>
            </v-container>
        
        </v-row>
        
      </v-container>
      
    </v-main>
  </v-app>
</template>
<script>

import "perfect-scrollbar/css/perfect-scrollbar.css";



import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: {
    Drawer,
    AppBar,
  },
  data() {
    return {
      url:'',
      drawer: null,
      sidebarColor: "success",
      sidebarTheme: "white",
      navbarFixed: false,
    };
  },
  methods: {
    goRoyyak(){
            window.open("https://royyak.com/");
    },

    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      let siblings = event.target.closest("button").parentElement.children;
      for (var i = 0; i < siblings.length; i++) {
        siblings[i].classList.remove("bg-gradient-default");
        siblings[i].classList.add("btn-outline-default");
      }
      event.target.closest("button").classList.add("bg-gradient-default");
      event.target.closest("button").classList.remove("btn-outline-default");
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
  },
  mounted() {
    // this.initScrollbar();
  },
};
</script>
<style scoped>
.not_mobile{
  padding-left: 277px !important;
}

</style>
