<template>
<v-navigation-drawer width="100%" height="calc(100% - 2rem)" fixed app floating :expand-on-hover="mini" :value="drawer" class="my-4 ms-4 border-radius-xl" :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'" :data-color="sidebarColor" :data-theme="sidebarTheme">
    <v-container style="text-align: -webkit-left;">
        <div class="">
            <v-img src="@/assets/logo.png" class="navbar-brand-img ms-3" width="50">
            </v-img>
            <!-- <span class="ms-2 text-purple text-sm">Checkmong.com</span> -->
            <h2>Token management</h2>
        </div>
    </v-container>
    <hr class="horizontal my-4" :class="sidebarTheme == 'dark' ? 'light' : 'dark'" />
    <!-- <v-list-item-title v-text="this.menu_title" class="text-purple" style="padding-left:22px; font-size:16px"></v-list-item-title> -->
        <v-list-item-group>
        <div v-for="(item, i) in itemsDocs" :key="i">
            <v-list-item link :to="item.path" class="pb-1 mx-2 no-default-hover py-2 left-menu" :ripple="false" active-class="item-active">
                <v-list-item-icon class="me-2 align-center">
                    <v-img :src="item.src" width="65px"></v-img>
                </v-list-item-icon>
                <v-list-item-content>
                    <span style="font-size:14px;white-space: pre-line;">{{item.title}}</span>
                </v-list-item-content>
            </v-list-item>

        </div>

    </v-list-item-group>
    <v-spacer></v-spacer>
    <br>
    <br>
    <br>
    <br>
    <br>
    <v-container class="mt-10">
        <v-row class="d-flex justify-center" >

            <v-btn @click="logOut" class="bg-purple text-white">
                Logout
            </v-btn>
        </v-row>
    </v-container>
</v-navigation-drawer>

</template>

<script>
export default {
    name: "draweradmin",
    props: {
        drawer: {
            type: Boolean,
            default: null,
        },
        sidebarColor: {
            type: String,
            default: "success",
        },
        sidebarTheme: {
            type: String,
            default: "white",
        },
    },
    data: () => ({
        user_type: "",
        menu_title: "",
        mini: false,
        togglerActive: false,
        thirdLevelSimple: [
            "Third level menu",
            "Just another link",
            "One last link",
        ],
        itemsDocs: [],
    }),
    mounted() {
        this.getCardMenu()
    },
    methods: {
        logOut() {
            // clear storage
            this.$store.commit('clearStore', '')
            localStorage.clear()
            localStorage.removeItem('store')
            // Logout Line
            this.$router.push('/home')
        },
        getCardMenu() {
            const user_provider_card = [{
                    title: "Dashboard",
                    src: require("@/assets/img/checkmong/Vectordab.png"),
                    path: "/admin_dashboard",
                },
                {
                    title: "Token Management",
                    src: require("@/assets/img/checkmong/OIP.jpeg"),
                    path: '/token_management'
                },
            ]
            this.itemsDocs = user_provider_card
   
        },
    },
};
</script>

<style scoped>
.left-menu:hover {
    background-color: #FFC90A15 !important;
    border-radius: 10px !important;
}

.v-navigation-drawer__content .item-active {
    background-color: #FFC90A15 !important;
    border-radius: 10px !important;
}

.wrap-text {
  -webkit-line-clamp: unset !important;
}
</style>
