<template>
  <v-app>
    <v-main class="">
      <div :style="image_md_up" class="imgbox_2">
        <v-container >
          <v-row class="min-vh-100">
              <v-container fluid>
                <router-view></router-view>
              </v-container>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>



export default {
  name: "page-layout",
  components: {
  },
  data() {
    return {
      image_md_up: {backgroundImage: `url(${require('@/assets/img/checkmong/landing-page/bg.png')})`},
    };
  },
  methods: {

  },
};
</script>
<style scoped>
.imgbox_2 {
background-size: cover;
/* background-position: 50%;
*/
background-position: center center; /* Center the image */
background-repeat: no-repeat; /* Do not repeat the image */
}

.img_mobile {
background-size: cover;
/* background-position: 50%;
*/
background-position: center center; /* Center the image */
background-repeat: no-repeat; /* Do not repeat the image */

}
</style>