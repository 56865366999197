<template>
  <v-app>

    <v-main class="" style="background-color: #d6609d">
    <!-- <v-main class="" style="background-color: white"> -->
        <router-view></router-view>
    </v-main>

  </v-app>
</template>
<script>
export default {
  name: "dm-layout",
  components: {},
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {},
};
</script>
<style>
.v-enter-active
{
  transition: opacity 1s ease;
}
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>