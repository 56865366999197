<template>
  <v-app>
    <v-main class="">
      <div :style="($vuetify.breakpoint.md || $vuetify.breakpoint.xl || $vuetify.breakpoint.lg) ? image_md_up : image_xs_sm" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm? 'img_mobile' : 'imgbox_2'">
        <v-container >
          <v-row class="min-vh-100">
              <v-container fluid>
                <router-view></router-view>
              </v-container>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>



export default {
  name: "page-layout",
  components: {
  },
  data() {
    return {
      image_md_up: {backgroundImage: `url(${require('@/assets/img/checkmong/landing-page/02-Eisai_BG_bk.jpg')})`},
      image_xs_sm: {backgroundImage: `url(${require('@/assets/img/checkmong/landing-page/02-Eisai_BG.jpg')})`},
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "SignUpBasic":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Welcome!";
        default:
          break;
      }
    },
  },
};
</script>
<style scoped>
.imgbox_2 {
background-size: cover;
/* background-position: 50%;
*/
background-position: right bottom; /* Center the image */
background-repeat: no-repeat; /* Do not repeat the image */
}

.img_mobile {
background-size: cover;
/* background-position: 50%;
*/
background-position: right bottom; /* Center the image */
background-repeat: no-repeat; /* Do not repeat the image */

}
</style>